<template>
<div class="container">
  <div class="row">

    <div class="col-md-12 col-xs-12">
      <div class="item">
        <h5>Центр   глазной   хирургии – Елабуга</h5>
        <a class="link-site" href="https://elabuga.mcgh.ru/">Сайт клиники</a>
        <h6>Татарстан, Елабуга, ул. Автомобилистов, д.4</h6>
         <p> Пн.-сб.: 9.00-19.00 <br>
          Вс.: 9.00-15.00
         </p>
        <a target="_blank" href="https://yandex.ru/maps/11123/elabuga/?from=mapframe&ll=52.017854%2C55.774431&mode=usermaps&source=mapframe&um=constructor%3A6041a2cc4e45d592d7987ec14b6e74effdff8ecea43e564ea6f0b77df7052617&utm_source=mapframe&z=17">
          Карта проезда
        </a>
        <br>
        <a href="/files/gde_ostonovitsa_Elabuga.docx" target="_blank">Где остановиться?</a>
      </div>
    </div>

    <div class="col-md-12 col-xs-12">
      <div class="item">
        <h5>Межрайонный центр глазной хирургии - п. Шемордан</h5>
        <a class="link-site" href="https://huzangaevo.mcgh.ru/">Сайт клиники</a>

        <h6>Татарстан, Сабинский район,
        п. Шемордан, ул. Фрунзе 6 «б»
        </h6>
        <p>
        Пн.-пт.: 8:00-17:00 <br>
        Сб.-вс: Выходной
        </p>
        <a href="https://yandex.ru/maps/?from=mapframe&ll=50.391391%2C56.179893&mode=usermaps&source=mapframe&um=constructor%3A31a2b96c14bcd36c704f3de15ea4fdb5a490cf5bf86b96ef6dabe8a8a442a5fc&utm_source=mapframe&z=17">
          Карта проезда
        </a>
        <br>
        <a href="/files/gde_ostonovitsa_Shemordan.docx" target="_blank" >Где остановиться?</a>
      </div>
    </div>

    <div class="col-md-12 col-xs-12">
      <div class="item">
        <h5>Межрайонный центр глазной хирургии – Хузангаево</h5>
        <a class="link-site" href="https://huzangaevo.mcgh.ru/">Сайт клиники</a>
        <h6>
          Татарстан, Алькеевский район,
        п. Хузангаево, ул. Школьная, д.28
        </h6>
        <p>
        ср.-вс.: 9:00-16:00 <br>
        Пн.-Вт.: Выходной
        </p>
        <a target="_blank" href="https://yandex.ru/maps/?from=mapframe&ll=49.862590%2C54.771869&mode=usermaps&source=mapframe&um=constructor%3A3695d34ae5cf2a4926bb842be14b6d87d19bff6f358eea54f333fc657e159d61&utm_source=mapframe&z=16">
          Карта проезда
        </a>
        <br>
        <a href="/files/gde_ostonovitsa_Khuzangaevo.docx" target="_blank">Где остановиться?</a>

      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "ContactsBlock"
}
</script>

<style scoped>
.row>div .item{
  border: solid 1px #e6e6e6;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
}
.container{
  margin-bottom: 50px;
  margin-top: 50px;
}

.row>div .item h5{
  color: #09b5f3;
  margin-bottom: 5px;
}
.link-site{
  margin-bottom: 15px;
  display: inline-block;
}
</style>