<template >
<div class="container">
  <div class="row">
    <div class="col-md-4 col-xs-12" id="desktop_tabs">
      <div class="tab-btn" v-bind:class="{ active: tab1 }" @click="switchTab('Tab1')">Катаракта</div>
      <div class="tab-btn" v-bind:class="{ active: tab2 }" @click="switchTab('Tab2')">Симптомы катаракты</div>
      <div class="tab-btn" v-bind:class="{ active: tab3 }" @click="switchTab('Tab3')">Лечение катаракты</div>
    </div>
    <div class="col-md-8 col-xs-12">
      <div class="col-md-4 col-xs-12 mobile-tabs" >
        <div class="tab-btn" v-bind:class="{ active: tab1 }" @click="tab1= !tab1">Катаракта</div>
      </div>
      <div class="tab-window" v-if="tab1">
        <h4>Катаракта </h4>
        <p>
          Катаракта – это помутнение хрусталика, развивающееся с возрастом, но может встречаться и у молодых людей.
          Изменения в хрусталике носят необратимый характер, и никакие капли, специальные очки, диета или упражнения
          не могут «заставить» его вновь стать прозрачным. При несвоевременном лечении катаракта может привести к слепоте.
        </p>

      </div>

      <div class="col-md-4 col-xs-12 mobile-tabs" >
        <div class="tab-btn" v-bind:class="{ active: tab2 }" @click="tab2= !tab2">Симптомы катаракты</div>
      </div>
      <div class="tab-window" v-if="tab2">
          <h4>Симптомы катаракты</h4>
          <ul>
            <li>Ухудшение ночного зрения;</li>
            <li>Ощущение бликов, вспышек, особенно в ночное время;</li>
            <li>Повышенная чувствительность к яркому свету;</li>
            <li>Появление ореолов вокруг источников света;</li>
            <li>Ослабление цветового восприятия (ухудшение цветового зрения);</li>
            <li>Расплывчатость, туманность изображения, не поддающаяся очковой коррекции;</li>
            <li>Появление близорукости;</li>
            <li>Трудности при чтении мелкого шрифта;</li>
            <li>Двоение в глазах.</li>
          </ul>
      </div>

      <div class="col-md-4 col-xs-12 mobile-tabs" >
        <div class="tab-btn" v-bind:class="{ active: tab3 }" @click="tab3= !tab3">Лечение катаракты</div>
      </div>
      <div class="tab-window" v-if="tab3">
        <h4>Лечение катаракты</h4>
        <p>
          Единственным способом устранения катаракты является микрохирургическая операция, заключающаяся в удалении помутневшего
          хрусталика глаза с заменой его на искусственный хрусталик (интраокулярную линзу). По своим оптическим свойствам
          интраокулярная линза сходна с естественным хрусталиком, ставится один раз на всю жизнь
        </p>
        <p>
          <strong>
            Катаракта полностью излечима. Чем раньше начато лечение, тем лучших результатов можно достичь!
          </strong>
        </p>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "TabsComponent",
  data(){
    return {
      tab1:true,
      tab2:false,
      tab3:false
    }
  },
  methods:{
    switchTab(item){
      if(item==="Tab1"){
        this.tab1=true
        this.tab2=false
        this.tab3=false
      }
      if(item==="Tab2"){
        this.tab1=false
        this.tab2=true
        this.tab3=false
      }
      if(item==="Tab3"){
        this.tab1=false
        this.tab2=false
        this.tab3=true
      }
    }
  }
}
</script>

<style scoped>
.container{
  padding-top: 118px;
  padding-bottom: 118px;
}
.tab-btn{
  color: #09A3F2;
  font-weight: bold;
  font-size: 18px;
  background: #EBF6FB;
  margin-bottom: 18px;
  padding: 24px 60px;
  border-radius: 10px;
  cursor: pointer;
}

.tab-btn.active{
  background:  #09A3F2;
  color: #fff;
}

.tab-window{
  box-shadow: 0px 0px 10px 3px #ccc;
  border-radius: 20px;
  padding: 30px;
}
.mobile-tabs{
  display: none;
}
@media screen and (max-width: 768px) {
  .container{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #desktop_tabs{
    display: none;
  }

  .mobile-tabs{
    display: block;
  }

  .tab-window{
    margin-bottom: 30px;
  }
}
</style>