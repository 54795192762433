<template>
 <section>

   <div class="block-1">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <h1>Лечение катаракты бесплатно по <br> полису ОМС для жителей всех регионов  России</h1>
          <div>
            <a href="#form" class="btn">Получить консультацию</a>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <img src="@/assets/images/block-1-bg.png" alt="">
        </div>
      </div>
    </div>
   </div>

   <TabsComponent />

   <PageBanner />

   <OMSBlock />

   <FormCallback />

   <WhyTrust />

   <ContactsBlock />

 </section>
</template>

<script>
import TabsComponent from "@/components/TabsComponent.vue";
import PageBanner from "@/components/PageBanner.vue";
import OMSBlock from "@/components/OMSBlock.vue";
import FormCallback from "@/components/FormCallback.vue";
import WhyTrust from "@/components/WhyTrust.vue";
import ContactsBlock from "@/components/ContactsBlock.vue";
export default {
  name: 'MainPage',
  components:{
    TabsComponent:TabsComponent,
    PageBanner:PageBanner,
    OMSBlock:OMSBlock,
    FormCallback:FormCallback,
    WhyTrust:WhyTrust,
    ContactsBlock,
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
  font-family: "Roboto", sans-serif;
}
.block-1{
  background: #09A3F2;
  padding-top: 119px;
}
.block-1 h1{
  color: #fff;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 40px;
}
.btn{
  background: #EBF6FB;
  color: #09A3F2;
  font-weight: bold;
  font-size: 16px;
  padding: 9px 25px;
}

.btn:hover{
  background: #dae2e5;
  color: #09A3F2;
}

@media screen and (max-width: 768px){
  .block-1{
    padding-top: 45px;
    text-align: center;
  }
  .block-1 img{
    width: 100%;
    margin-top: 45px;
  }
  .block-1 h1{
    text-align: center;
   font-size: 28px;
  }
}
</style>
