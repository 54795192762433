<template>
  <Page_Header />
  <MainPage />
<PageFooter />
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'
import MainPage from './pages/MainPage.vue'

export default {
  name: 'App',
  components: {
    MainPage: MainPage,
    Page_Header:PageHeader,
    PageFooter:PageFooter
  }
}
</script>

<style>

</style>

