<template>
<div class="form-callback" id="form">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">Записаться на прием</h2>
        <form v-on:submit.prevent>
          <input type="text" v-model="name" placeholder="Ваше имя" required>
          <input type="text" v-model="phone" required placeholder="+7 999 999 9999" v-maska="'+7 ### ### ####'">
          <button @click="sendForm">
            Отправить
          </button>
        </form>
        <p id="police" class="text-center">Нажимая на кнопку, вы даете согласие на обработку своих персональных
          данных и соглашаетесь с <br>
          <a href="https://rascheskoff.ru/obrabotka-pers-dannyih/">Пользовательским соглашением</a></p>
      </div>

      <div v-if="submitForm" class="text-center">
        <p>Ваша заявка успешно отправлена!</p>
      </div>
      <div v-if="formEmpty" class="text-center" style="color: red; font-weight: bold">
        <p>Заполните форму</p>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "FormCallback",
  data(){
    return {
      name: "",
      phone: "",
      submitForm:false,
      formEmpty:false
    }
  },
  methods:{
    sendForm(){

      if(!this.name || !this.phone){
        this.formEmpty=true
        return;
      }else {
        this.formEmpty=false
      }

      var params = new FormData();
      params.set('name', this.name);
      params.set('phone', this.phone);

      fetch(
          '/send/',
          {
            method: 'POST',
            body: params
          }
      )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong');
          })
          .then(() => {
            this.submitForm=true;
            this.name=""
            this.phone=""
            this.sendSEOTarget()
          })
          .catch(() => {
            this.submitForm=false;
          });
    },
    /**
     * Отправка цели Yandex.Metriaka
     * Для проверки работы цели добавить в УРЛ параметр ?_ym_debug=1
     * @param {Object} args
     * @param {String} args.reachGoal имя цели
     * @param {Number} args.counterID ID счетчика
     */
    sendSEOTarget(
        args = {
          counterID: 91530464,
          reachGoal: 'formReceprionFooter'
        }
    ) {
      try {
        window[`yaCounter${args.counterID}`].reachGoal(args.reachGoal);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style scoped>
.form-callback{
  background: #09A3F2;
  color: #fff;
  padding: 35px 0;
}
form{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
form input{
  margin-right: 15px;
  border-radius: 5px;
  width: 366px;
  border: none;
  border-radius: 7px;
  padding: 10px 15px;
  margin-top: 20px;

}
form button{
  background: #EBF6FB;
  color: #09A3F2;
  font-weight: bold;
  padding: 7px 30px;
  border-radius: 7px;
  border:none;
  font-size: 20px;
}
#police{
  margin-top: 15px;
}
#police a{
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  form{
    flex-direction: column;
    align-items: center;
  }
  form input{
    width: 100%;
    margin-right: 0;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
</style>